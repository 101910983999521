import { Container,  Col} from 'react-bootstrap'
import { Item } from './main'
import React, { Component } from 'react'
import covid from '../assets/img/dini/covid.svg'

export class Covid extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        return (
            <>
                <Container className="py-3">
                    <Item>
                        <Col xs={12} md={6}>
                            <img src={covid} className="w-100 img-fluid" />
                        </Col>
                    </Item>
                </Container>
            </>
        )
    }

}
