import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Timer } from '../components/timer'
import { Covid } from '../components/covid'
import { Helm } from '../components/header'
import { Row, Col, Alert, DropdownButton, Dropdown, Toast } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/dini.mp3'
import logoig from '../assets/img/aulia/logoig.svg'
import burung from '../assets/img/aulia/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

let cmain = '#69785b'
let black = 'rgb(38,38,38)'
let id = 'dini-dodi'
let inisial_co = 'Dodi'
let inisial_ce = 'Dini'

let lengkap_co = (<>Dodi Anugrah, S.Farm</>)
let lengkap_ce = (<>apt. Diniar Nur Falah, S. Farm</>)

let bapak_co = 'Bpk Holillullah, Ama. Pd (Alm) '
let ibu_co = 'Ibu Yusliah'
let bapak_ce = "Bpk M. Nasir Hamid, SH"
let ibu_ce = "Ibu Ns. Juryeni, S.Kep"

let ig_co = "dodianugrah13"
let ig_ce = "diniarnf"

let foto_ce = "https://i.ibb.co/hs3752C/DSC08121.jpg"
let foto_co = "https://i.ibb.co/tDc37zS/DSC08121-1.jpg"
let waktunikah = "12/16/2020"

let modal = "https://i.ibb.co/gvsfg59/Modal.jpg"
let openlogo = "https://i.ibb.co/WxpdMZs/Logo-Fix.png"

let gmaps = "https://g.page/MasonPineHotel?share"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NjBrdmJqMXM1Zjc2a2x0MjZ2bmU2OThwbGsgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ["	https://i.ibb.co/QJQ2XFF/DSC08124.jpg	",
    "	https://i.ibb.co/Zznybrk/DSC08431.jpg	",
    "	https://i.ibb.co/VjWfjR7/DSC08437.jpg	",
    "	https://i.ibb.co/J2Md5kb/DSC08484.jpg	",
    "	https://i.ibb.co/CndQrP3/DSC08609.jpg	",
    "	https://i.ibb.co/qsN0XWH/DSC08733.jpg	",
    "	https://i.ibb.co/smjZdvM/DSC08772-2-1.jpg	",
    "	https://i.ibb.co/F79vzd7/DSC077841.jpg	",
    "	https://i.ibb.co/s94nT75/DSC07989.jpg	",
    "	https://i.ibb.co/L1Gbv4w/DSC08066.jpg	",
    "	https://i.ibb.co/kBtfpQj/DSC08121.jpg	",

]

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: false,
            err: [],
            submitted: '',
            sesi: 1,
            comment: [],
            friend: null,
            show: false,
            show1: false,
            showgift: false,
            shownew: false
        }
    }


    componentDidMount = async () => {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment=await getData('kepada :"dini-dodi"')
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        console.log(local)
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "dini-dodi", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, hide, submitted, err, showgift, comment, shownew, sesi } = this.state

        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=480'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${gambar('https://i.ibb.co/Lnj409g/imageedit-4-8999229522.png', 95)})`,

                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative p-0 m-0' style={{
                            backgroundImage: `url(${modal})`
                        }}>
                            <div style={{ position: 'absolute', bottom: '10%', width: "100%" }}>
                                <Row >
                                    <Col xs={11} md={3} className='mx-auto'>
                                        <img className='img-fluid w-100 '
                                            src={!hide ? "" : gambar(openlogo)} data-aos="fade-left" />
                                    </Col>
                                </Row>
                                {
                                    !hide ? false : (
                                        <h2 className={`w-100 col-md-4 roboto-slab text-center py-3`} style={{ marginTop: '0' }}>
                                            <br /> {query ? `Kepada Yth : ${query}` : ''} <br /></h2>
                                    )
                                }
                                <Row className='justify-content-center pt-4'>
                                    <div onClick={() => { this.play() }}

                                        className={` btn col-md-4 button roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                        style={{ marginTop: 0, color: 'white' }}>
                                        Open Invitation
                            </div>
                                </Row>


                            </div>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                {/* <Container >
                                    <Item >
                                        <Col xs={10} md={6}>
                                            <img src={gambar('https://i.ibb.co/cFtJ3sY/Logo-Aulia-Dalam.png')} className='w-100 img-fluid' />
                                        </Col>
                                    </Item>
                                </Container> */}
                                <Container className="dinny px-4 pt-2 ">
                                    <Item>

                                        <p className="fs24 text-center w-100 cblack">
                                            <span className="fs16">
                                            “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.” <br /><br />(Ar-Rum: 21)
                                         </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} md={4}>
                                            <img src='https://webstockreview.net/images/clipart-flower-hipster-2.png' data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>


                                <Timer cmain={cmain} waktunikah={waktunikah} />


                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={8}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <p style={{ fontSize: '16px' }} className="w-100 text-center">
                                                    <b>Putri dari pasangan: </b><br />
                                                    Bapak Wawan Setiawan M.Pd<br />
                                                    &<br />
                                                    Ibu Siti Halimah, M.Pd<br />
                                                </p>

                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={8}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '24px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <p style={{ fontSize: '16px' }} className="w-100 text-center">
                                                    <b>Putra dari pasangan:</b><br />
                                                    Bapak Nana Sukana (Alm)<br />
                                                    &<br />
                                                    Ibu Eneng Masriah<br />
                                                </p>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>

                                <div style={{

                                }}>


                                    <Container fluid className="text-center px-4 dinny py-3" style={{ color: black }} >
                                        <Item>
                                            <p className="fs16">
                                                Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                                        </Item>
                                        <Item>
                                            <p className="fs20">
                                                <b>
                                                    RABU <span className="fs36">16</span> DES 2020
                      </b>
                                            </p>
                                        </Item>
                                        <Item>
                                            <p className="fs20 col-md-4" style={{ color: cmain }}>
                                                <div
                                                    style={{ fontStyle: 'bold', fontSize: '36px',
                                                     fontFamily: 'Marck Script, cursive' }}>
                                                    <b>Akad Nikah </b><br /></div>
                                                <span className="cblack fs16">
                                                    
                                                    08.00 WIB - Selesai<br/>
                                                    <div style={{ fontStyle: 'italic' }}>
                                                        (Hanya dihadiri keluarga)
                                                        </div>
                                                </span><br />
                                            </p>
                                            <p className="px-3 d-none d-md-block" style={{ color: cmain, fontSize: '72px' }}>
                                                \
                                                </p>
                                            <div className="col-8 d-lg-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                            </div>
                                            <p className="fs20 pt-3 pt-md-0 col-md-4" style={{ color: cmain }}>
                                                <div style={{ fontSize: '36px', fontFamily: 'Marck Script, cursive' }}><b>Resepsi</b><br /> </div>
                                                <span className="cblack fs16" style={{ fontStyle: 'bold' }}>

                                                    11.00 WIB - 14.00 WIB
                                                     </span> <br />
                                                     <div style={{ fontStyle: 'italic' }}>
                                                        (Undangan hanya untuk 1 orang)
                                                        </div>
                                            </p>
                                        </Item>
                                        <Item>
                                            <p className="fs16 pt-3">
                                                <img src='https://www.flaticon.com/svg/static/icons/svg/2928/2928883.svg' className='img-fluid' style={{ width: '40px', height: '40px' }} /><br /><span >
                                                    <b> Mason Pine Hotel </b><br />
                                                Jl. Raya Parahyangan KM. 1.8 Kota Baru, Cipeundeuy, Padalarang, Kabupaten Bandung Barat, Jawa Barat 40714

                                                </span>
                                            </p>
                                        </Item>
                                        <Item>
                                            <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                                <iframe width="400" height="300" id="gmap_canvas"
                                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7922.436709325086!2d107.4798445!3d-6.864416!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e4b32c5e15d7%3A0xebd0dc3bfc0de869!2sMASON%20PINE%20HOTEL!5e0!3m2!1sid!2sid!4v1607161741859!5m2!1sid!2sid" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div>
                                            </div>
                                        </Item>
                                        <Item>
                                            <Col xs={10} md={3}
                                                style={{
                                                    border: `2px solid ${cmain}`,
                                                    borderRadius: '10px'
                                                }}
                                                onClick={() => {
                                                    window.open(gmaps)
                                                }}
                                                className="btn p-2 mx-md-2">
                                                <Item>
                                                    <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                        style={{ width: "10%", height: '10%' }} />
                                                    <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                        <b>Get Direction</b>
                                                    </p>
                                                </Item>
                                            </Col>
                                            <Col
                                                onClick={() => window.open(gcalendar)}
                                                xs={10} md={3}
                                                style={{
                                                    border: `2px solid ${cmain}`,
                                                    borderRadius: '10px'
                                                }}
                                                className="btn p-2 mx-md-2 mt-3 mt-md-0">
                                                <Item>
                                                    <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                        style={{ width: "10%", height: '10%' }} />
                                                    <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                        <b>Add to Calendar</b>
                                                    </p>
                                                </Item>
                                            </Col>
                                        </Item>
                                    </Container>
                                    <Covid />

                                    <Container className='mt-3 py-3 ' data-aos="fade-right" data-aos-duration="1000">
                                        <Item>
                                            <div >
                                                <h1 style={{ color: cmain, fontStyle: 'bold', fontWeight: 700, fontSize: '72px',  fontFamily: 'Marck Script, cursive' }}>
                                                    The Moment
                        </h1>
                                            </div>
                                        </Item>
                                        <Slider slide={slider} />
                                    </Container>
                                    <Container id='sectiongold56' className="py-3 px-4" >
                                        <div className='pt-3'>
                                            <div data-aos={`fade-right`} data-aos-duration="2000">
                                                <Item>
                                                    <div className='kotak col-12 position-relative' style={{ backgroundColor: 'white', overflow: 'hidden' }}>

                                                        <Item>
                                                            <p className='text-center p-2 px-4 pt-5 amatic' style={{ zIndex: 1, color: 'black' }}>
                                                            Tidak ada solusi yang lebih baik bagi dua insan yang saling mencintai dibanding pernikahan.” - HR. Ibnu Majah
                              </p>
                                                        </Item>
                                                    </div>
                                                </Item>
                                            </div>
                                        </div>
                                    </Container>
                                </div>
                                <Container id='sectiongold58' className="mt-3 py-3" fluid style={{ backgroundColor: cmain }}>
                                    <Item>
                                        <Col xs={11}>
                                            <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                                <Item>
                                                    <Col xs={4} lg={2}>
                                                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <Col xs={12} md={6}>
                                                        <div className='col-12 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                            <Item>
                                                                <h1 style={{
                                                                    fontFamily: '"Marck Script", cursive',
                                                                    color: cmain
                                                                }}>
                                                                    Send Your Wishes
                              </h1>
                                                            </Item>
                                                            <Item>
                                                                <form className="col-12 w-100">
                                                                    <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                                                                    <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />

                                                                    <Item>
                                                                        <div id="formradio">
                                                                            <div class="custom_radio row justify-content-center">
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: true })
                                                                                }
                                                                                }>
                                                                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                                    <label style={{ color: cmain }} for="featured-1">Hadir</label>
                                                                                </div>
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: false })
                                                                                }
                                                                                } className="pl-5">
                                                                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                                    <label style={{ color: cmain }} for="featured-2"
                                                                                    >Tidak Hadir</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Item>
                                                                    
                                                                    <Item>
                                                                        <Col xs={12} className='' >
                                                                            {
                                                                                submitted == true ? (
                                                                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                        Pesan anda sudah disampaikan
                                                                                    </Alert>) : (submitted === false ? (
                                                                                        <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                            {
                                                                                                err.map(val => {
                                                                                                    return (
                                                                                                        <li>{val}</li>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Alert>
                                                                                    ) : false)
                                                                            }

                                                                        </Col>
                                                                    </Item>
                                                                    <Item>
                                                                        <div className='btn col-6 button rounded'
                                                                            onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                                    </Item>
                                                                </form>
                                                            </Item>
                                                        </div>

                                                    </Col>

                                                    <Col xs={12} md={6}>
                                                        <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                            {comment.map(v => {
                                                                return (
                                                                    <Item>
                                                                        <Col xs={2}>
                                                                            <img src='https://www.flaticon.com/svg/static/icons/svg/3447/3447670.svg' className="img-fluid w-100" />
                                                                        </Col>
                                                                        <Col xs={10}>
                                                                            <Alert variant="secondary col-12">
                                                                                <p style={{ fontSize: '16px' }} className='m-0'>
                                                                                    {v.pesan}
                                                                                </p>
                                                                            </Alert>
                                                                        </Col>
                                                                    </Item>
                                                                )
                                                            })}
                                                        </div>
                                                    </Col>
                                                </Item>
                                            </div>

                                        </Col>
                                    </Item>
                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

